import { Loader } from "@ahlsell-group/ui-kit/feedback";
import classNames from "classnames";
import React, { HTMLAttributes, useEffect } from "react";

import { CameraError, OnBarcodesScannedHandler } from "../types";
import useBarcodeScanner from "../useBarcodeScanner";

export interface ScannerViewportProps {
  cameraOn: boolean;
  scannerOn: boolean;
  onBarcodeScanned?: OnBarcodesScannedHandler;
  onError?: (e?: CameraError) => void;
  initializationClassName: HTMLAttributes<HTMLDivElement>["className"];
}

const noop = () => {};

const ScannerViewport: React.FC<ScannerViewportProps> =
  function ScannerViewport({
    cameraOn,
    scannerOn,
    onBarcodeScanned,
    onError,
    initializationClassName,
  }) {
    const { scanningState, scannerViewRef, error } = useBarcodeScanner(
      {
        camera: cameraOn,
        scanner: scannerOn,
      },
      onBarcodeScanned ?? noop
    );

    useEffect(() => {
      if (scanningState.state === "error" && onError) {
        onError(error);
      }
    }, [error, onError, scanningState.state]);

    if (scanningState.state === "initializing") {
      return (
        <div
          className={classNames(
            "absolute inset-0 flex justify-center items-center",
            initializationClassName
          )}
        >
          <Loader size="large" />
        </div>
      );
    }
    // Since we show content over the camera, the camera needs to be under the WebView, hence the negative z-value.
    // Source: https://docs.scandit.com/data-capture-sdk/capacitor/core/api/ui/data-capture-view.html#method-scandit.datacapture.core.ui.DataCaptureView.ConnectToElement
    return <div className="absolute inset-0 -z-10" ref={scannerViewRef} />;
  };

export default ScannerViewport;
