import { Loader } from "@ahlsell-group/ui-kit/feedback";
import { miniSerializeError } from "@reduxjs/toolkit";
import React, { useState } from "react";

import useServiceContainer from "../../../app/components/ServiceContainerContext";
import { UnhandledErrorInfo } from "../errorSlice";
import logUnhandledError from "../logUnhandledError";
import useUncaughtErrorLogger from "../useUncaughtErrorLogger";

import ErrorBoundary from "./ErrorBoundary";
import UnhandledError from "./UnhandledError";

type Display = "children" | "loader" | UnhandledErrorInfo;

type ErrorBoundaryRootProps = React.PropsWithChildren<unknown>;

const ErrorBoundaryRoot: React.FC<ErrorBoundaryRootProps> = function ({
  children,
}) {
  useUncaughtErrorLogger();
  const { logService } = useServiceContainer();
  const [display, setDisplay] = useState<Display>("children");

  const tryAgain = () => {
    setDisplay("loader");
    setTimeout(() => setDisplay("children"), 300);
  };

  const handleError = (error: Error, errorInfo: React.ErrorInfo) => {
    logUnhandledError(logService, "unhandled:react", error, errorInfo);

    setDisplay({ ...miniSerializeError(error), ...errorInfo });
  };

  if (display === "children") {
    return <ErrorBoundary onDidCatch={handleError}>{children}</ErrorBoundary>;
  }

  if (display === "loader") {
    return (
      <div className="grid place-items-center h-full">
        <Loader size="large" />
      </div>
    );
  }

  return <UnhandledError error={display} onTryAgain={tryAgain} />;
};

export default ErrorBoundaryRoot;
