import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Loader } from "@ahlsell-group/ui-kit/feedback";
import { Button } from "@ahlsell-group/ui-kit/inputs";
import { Center } from "@ahlsell-group/ui-kit/layout";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useNavigate from "../../routing/useNavigate";
import { selectNearbyStoresState } from "../nearbyStoresSelectors";
import { storesRequired } from "../nearbyStoresSlice";

import ItemStock from "./ItemStock";
import OtherWarehousesError from "./OtherWarehousesError";

type NearbyStoresStockProps = {
  itemId: string;
};

const NearbyStoresItemStock: React.FC<NearbyStoresStockProps> = function ({
  itemId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const nearbyStoresState = useSelector(selectNearbyStoresState);

  useEffect(() => {
    if (nearbyStoresState.state === "idle") {
      dispatch(storesRequired());
    }
  }, [nearbyStoresState, dispatch]);

  if (nearbyStoresState.state === "loading") {
    return (
      <PageSection>
        <Center variant="center" className="text-center">
          <Typography variant="body" className="mb-12">
            {t("itemStock.loadingStores")}
          </Typography>
          <Loader size="large" className="inline-block" />
          <div className="mt-12">
            <Button
              variant="tertiary"
              width="content"
              onClick={() => navigate.back()}
            >
              {t("cancel")}
            </Button>
          </div>
        </Center>
      </PageSection>
    );
  }

  if (nearbyStoresState.state === "loaded") {
    return (
      <div data-cy="NearbyStores">
        <PageSection padding="x" className="pb-2">
          <Typography variant="form-label" color="gray">
            {t("itemStock.closestStores")}
          </Typography>
        </PageSection>
        <ul data-cy="NearbyStores-list">
          {nearbyStoresState.stores.map((store) => (
            <li
              key={store.storeId}
              data-cy={`NearbyStores-store-id-${store.storeId}`}
            >
              <PageSection className="border-b">
                <Typography
                  variant="body"
                  className="pb-1"
                  data-cy="NearbyStores-store-name"
                >
                  {store.storeName}
                </Typography>
                <div className="pb-1">
                  <ItemStock
                    warehouseId={store.storeId}
                    itemId={itemId}
                    size="small"
                  />
                </div>
                <Typography
                  variant="body-sm"
                  data-cy="NearbyStores-store-street"
                >
                  {store.street}
                </Typography>
              </PageSection>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (nearbyStoresState.state === "error") {
    return (
      <div>
        <OtherWarehousesError
          title={t("itemStock.closestStores")}
          message={t("itemStock.fetchNearbyStoresError")}
          data-cy="NearbyStores-error"
        />
      </div>
    );
  }

  // Idle state.
  return null;
};

export default NearbyStoresItemStock;
