import {
  Item,
  ValidatedItem,
} from "@ahlsell-group/store20-stock-taking-service";

import { Selector } from "../../types";

import {
  GetOrCreateManualStockTakeFailedReason,
  ManualStockTakingState,
  ManualStockTakingStatus,
  ReviewStockTakeError,
  SubmitStockTakesError,
} from "./manualStockTakingSlice";

export const selectManualStockTakingStatus: Selector<
  ManualStockTakingStatus
> = (state) => state.manualStockTaking.status;

export const selectIsLoadingManualStockTake: Selector<boolean> = (state) =>
  state.manualStockTaking.initStockTakeState === "loading" ||
  state.manualStockTaking.isReviewEnabled === undefined;

export const selectInitStockTakeError: Selector<
  GetOrCreateManualStockTakeFailedReason | undefined
> = (state) =>
  typeof state.manualStockTaking.initStockTakeState === "object"
    ? state.manualStockTaking.initStockTakeState.reason
    : undefined;

export const selectManualStockTakingOpenItem: Selector<
  ManualStockTakingState["openItem"]
> = (state) => state.manualStockTaking.openItem;

export const selectItems: Selector<Item[]> = (state) =>
  state.manualStockTaking.items;

export const selectIsStockTakeInProgress: Selector<boolean> = (state) =>
  state.manualStockTaking.items.length !== 0;

export const selectSubmitStockTakesError: Selector<
  SubmitStockTakesError | undefined
> = (state) => state.manualStockTaking.submitStockTakesError;

export const selectCurrentStockTakeId: Selector<string | undefined> = (state) =>
  state.manualStockTaking.currentStockTakeId;

export const selectIsReviewEnabled: Selector<boolean | undefined> = (state) =>
  state.manualStockTaking.isReviewEnabled;

export const selectIsReviewLoading: Selector<boolean> = (state) =>
  state.manualStockTaking.reviewState === "loading";

export const selectReviewLoadError: Selector<
  ReviewStockTakeError | undefined
> = (state) =>
  typeof state.manualStockTaking.reviewState === "object"
    ? state.manualStockTaking.reviewState.error
    : undefined;

export const selectReviewData: Selector<ValidatedItem[] | undefined> = (
  state
) =>
  typeof state.manualStockTaking.reviewState === "object"
    ? state.manualStockTaking.reviewState.data
    : undefined;

export const selectManualStockTakingDeleteItemState: Selector<
  ManualStockTakingState["deleteItemState"]
> = (state) => state.manualStockTaking.deleteItemState;

export const selectManualStockTakingDeleteItemError: Selector<
  ManualStockTakingState["deleteItemError"] | undefined
> = (state) => state.manualStockTaking.deleteItemError;
