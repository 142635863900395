import { AuthenticationStatus } from "@ahlsell-group/store20-authentication-service";
import { call, put, select } from "redux-saga/effects";
import * as router5 from "router5";

import startBackgroundUpdateLoopSaga from "../../features/background-update/sagas/startBackgroundUpdateLoopSaga";
import runGettingStartedSaga from "../../features/getting-started/sagas/runGettingStartedSaga";
import { selectCurrentRoute } from "../../features/routing/routingSelectors";
import loadUserWarehouseInfoSaga from "../../features/warehouse/sagas/loadUserWarehouseInfoSaga";
import { Saga } from "../../types";
import { getApiContextForUser } from "../apiContexts";
import { appStarted, appStartedInInstallMode } from "../appSlice";
import getMockServerConfig from "../getMockServerConfig";
import { ServiceContainer } from "../serviceContainer";

import { startNavSaga } from "./navSagaRoot";

export default function* startAppSaga(
  serviceContainer: ServiceContainer
): Saga {
  serviceContainer.nativeAppService.bootstrapEvents();

  const currentRoute: router5.State | undefined = yield select(
    selectCurrentRoute
  );

  if (currentRoute && currentRoute.name.startsWith("install")) {
    yield put(appStartedInInstallMode());
    return;
  }

  yield* runGettingStartedSaga(serviceContainer);

  const { user }: AuthenticationStatus = yield call([
    serviceContainer.authenticationService,
    "ensureLoggedIn",
  ]);

  if (!user) {
    throw new Error("No user after Getting Started flow");
  }

  serviceContainer.appTelemetryService.updateSessionInfo({
    employeeId: user.employeeId,
    name: user.name,
    email: user.email,
    legalEntityId: user.legalEntityId,
    warehouseId: user.warehouseId,
  });
  serviceContainer.apiContextService.setContext(getApiContextForUser(user));

  // TODO - Change this once we have email addresses to Google and Apple.
  if (user.email === "andre.hammarstig@ahlsell.se") {
    const mockServerConfig = getMockServerConfig(
      serviceContainer.configManager.getConfig()
    );
    serviceContainer.configManager.updateConfig({
      ...mockServerConfig,
    });
  }

  yield startBackgroundUpdateLoopSaga(serviceContainer);
  yield startNavSaga(serviceContainer);
  yield loadUserWarehouseInfoSaga(serviceContainer);

  yield put(appStarted());
}
