import {
  Trans,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert, Loader } from "@ahlsell-group/ui-kit/feedback";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import typescriptAssertNever from "../../../util/typescriptAssertNever";
import { selectStockTakenSubmissionState } from "../stockTakenSubmissionSelectors";
import { submissionsRequested } from "../stockTakenSubmissionSlice";

import StockTakeSubmissionListItem from "./StockTakeSubmissionListItem";

interface StockTakenSubmissionListProps {
  highlightVivaldiId?: string;
}
const StockTakenSubmissionList: React.FC<StockTakenSubmissionListProps> =
  function ({ highlightVivaldiId }) {
    const { t } = useTranslation("common");
    const stockTakenSubmissionStatus = useSelector(
      selectStockTakenSubmissionState
    );
    const dispatch = useDispatch();

    const errorMessage = useErrorMessage(
      "stockTakenSubmission",
      stockTakenSubmissionStatus.type === "error"
        ? stockTakenSubmissionStatus.error
        : "unknown"
    );

    if (stockTakenSubmissionStatus.type === "idle") {
      throw new Error(
        "[internal error] StockTakenSubmission stuck in idle state"
      );
    }

    if (stockTakenSubmissionStatus.type === "error") {
      const headerKey = errorMessage
        ? errorMessage.headerKey
        : "errors.stockTakenSubmission.unknown__header";
      const detailKey = errorMessage
        ? errorMessage.detailKey
        : "errors.stockTakenSubmission.unknown__detail";

      return (
        <div className="py-4" data-cy="StockTakeSubmissions-error">
          <Alert severity="warning">
            <Typography variant="body-sm">
              <strong>
                <Trans t={t} i18nKey={headerKey} />
              </strong>
            </Typography>
            <Typography variant="body-sm">
              <Trans t={t} i18nKey={detailKey} />
            </Typography>

            <LinkButton
              className="-ml-2"
              onClick={() => dispatch(submissionsRequested())}
            >
              {t("tryAgain")}
            </LinkButton>
          </Alert>
        </div>
      );
    }

    if (stockTakenSubmissionStatus.type === "loading") {
      return (
        <div
          className="flex flex-col py-4"
          data-cy="StockTakeSubmissions-loading"
        >
          <Typography className="px-2 pb-4" color="gray" variant="body-sm">
            {t("stockTaking.loadingStockTakenSubmissions")}
          </Typography>
          <Loader className="self-center" size="large" />
        </div>
      );
    }

    if (stockTakenSubmissionStatus.type === "loaded") {
      const { submissions } = stockTakenSubmissionStatus;
      return (
        <div>
          {submissions.length !== 0 ? (
            <>
              <Typography className="py-4 px-2" color="gray" variant="body-sm">
                {t("stockTaking.stockTakenSubmissions")}
              </Typography>
              <div data-cy="StockTakenSubmissions-list">
                {submissions.map((submission) => (
                  <StockTakeSubmissionListItem
                    key={submission.vivaldiId}
                    submission={submission}
                    highlight={
                      highlightVivaldiId === submission.vivaldiId?.toString()
                    }
                  />
                ))}
              </div>
            </>
          ) : (
            <Typography
              className="pt-4 px-2"
              color="gray"
              variant="body-sm"
              data-cy="StockTakeSubmissions-empty"
            >
              {t("stockTaking.stockTakenSubmissionsEmpty")}
            </Typography>
          )}
        </div>
      );
    }

    typescriptAssertNever(stockTakenSubmissionStatus);

    return null;
  };

export default StockTakenSubmissionList;
