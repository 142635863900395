import { useTranslation } from "@ahlsell-group/app-localization";
import { AceChip } from "@ahlsell-group/app-ui/AceChip";
import { StockTake } from "@ahlsell-group/store20-stock-taking-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import classNames from "classnames";
import React from "react";

import { getDate, getTime, isToday } from "../util/date";

interface StockTakeSubmissionListItemProps {
  submission: StockTake;
  highlight: boolean;
}
const StockTakeSubmissionListItem: React.FC<StockTakeSubmissionListItemProps> =
  function ({ submission, highlight }) {
    const { t } = useTranslation("common");
    // TODO : Use these styles once we have diff and error statuses.
    const submissionDiff = false;
    const submissionError = false;

    const { submittedDateTimeUtc, vivaldiId } = submission;

    const today = submittedDateTimeUtc && isToday(submittedDateTimeUtc);

    return (
      <div
        className={classNames(
          "bg-theme-secondary-bg-50 px-3 mb-2 rounded relative",
          {
            "border-l-8 border-l-theme-secondary-notification-attention":
              submissionDiff,
            "border-l-8 border-l-theme-status-text-error": submissionError,
          }
        )}
      >
        {today && (
          <AceChip
            color="success"
            wiggle={highlight}
            className="absolute -right-1 -top-2 mt-px"
          >
            {t("stockTakingReview.new")}
          </AceChip>
        )}
        <div className="flex items-center pt-1">
          <Typography
            variant="body"
            component="span"
            className="grow"
            data-cy="StockTakenSubmission-submission-id"
          >
            #{vivaldiId}
          </Typography>
          <div className="flex flex-col text-right">
            <Typography variant="body-sm" className="flex-1" component="span">
              {submittedDateTimeUtc && getTime(submittedDateTimeUtc)}
            </Typography>
            <Typography
              variant="body-sm"
              component="span"
              color="gray"
              className="flex-1"
            >
              {submittedDateTimeUtc &&
                (today ? t("today") : getDate(submittedDateTimeUtc))}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

export default StockTakeSubmissionListItem;
