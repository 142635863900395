import { useTranslation } from "@ahlsell-group/app-localization";
import { Item } from "@ahlsell-group/store20-stock-taking-service";
import { CommentIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Chip } from "@ahlsell-group/ui-kit/feedback";
import React from "react";

import ItemImage from "../../item-image/components/ItemImage";

export interface ManualStockTakingListItemProps {
  item: Item;
  onSelect(): void;
}

const ManualStockTakingListItem: React.FC<ManualStockTakingListItemProps> =
  function ({ item, onSelect }) {
    const { t, i18n } = useTranslation("common");

    const isExpectedQuantity =
      item.stockTakingQuantity === item.expectedQuantityWhenCounted;

    return (
      <button
        type="button"
        className="relative flex flex-row items-stretch rounded w-full text-left p-4 mb-2 bg-white"
        onClick={() => onSelect()}
        data-cy={`ManualStockTakingListItem-button-${item.itemId}`}
      >
        <ItemImage
          assetUrl={item.imagePath ?? ""}
          alt={item.description2 ?? ""}
          size="small"
        />

        <Typography
          variant="body-sm"
          className="ml-4 flex-1 flex flex-col justify-between"
        >
          <div className="text-gray">{item.description1}</div>
          <div className="flex items-center">
            <div className="grow">
              {t("stockTaking.articleNumberShort")} {item.itemId}
            </div>
            {item.comment && (
              <span className="inline-block" title={item.comment}>
                <CommentIcon size="small" className="ml-2" />
              </span>
            )}
            <Chip
              // TODO "attention"
              color={isExpectedQuantity ? "default" : "warning"}
              className="ml-2"
              data-cy="ManualStockTakingListItem-quantity"
            >
              {Intl.NumberFormat(i18n.language).format(
                item.stockTakingQuantity ?? 0
              )}
              &nbsp;{item.unit}
            </Chip>
          </div>
        </Typography>
      </button>
    );
  };

export default ManualStockTakingListItem;
