import barcodeScannerRoutes from "../barcode-scanning/barcodeScannerRoutes";
import itemSearchRoutes from "../item-search/itemSearchRoutes";
import { createRoutes } from "../routing/utils";

const stockTakingRoutes = createRoutes({
  stockTaking: {
    path: "/stock-taking?highlightVivaldiId",
    children: {
      manual: {
        path: "/manual?goto&clear&modal",
        children: {
          ...barcodeScannerRoutes,
          ...itemSearchRoutes,
          item: {
            path: "/item/:itemId?autofocus&acceptQuantity&modal",
            children: {
              /** `:itemId` is a barcode in this path */
              barcode: { path: "/barcode" },
              remove: { path: "/remove" },
              diff: { path: "/diff" },
              comment: { path: "/comment" },
            },
          },
          submit: {
            path: "/submit",
            children: {
              complete: { path: "/:stockTakeId" },
              error: { path: "/error" },
            },
          },
        },
      },
    },
  },
} as const);

export default stockTakingRoutes;
