import i18n, { useTranslation } from "@ahlsell-group/app-localization";
import { SlideUpModal } from "@ahlsell-group/app-ui/SlideUpModal";
import { ValidatedItem } from "@ahlsell-group/store20-stock-taking-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Button } from "@ahlsell-group/ui-kit/inputs";
import classNames from "classnames";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import ErrorModal from "../../error/components/ErrorModal";
import useNavigate from "../../routing/useNavigate";
import {
  selectIsReviewLoading,
  selectManualStockTakingStatus,
  selectReviewData,
  selectReviewLoadError,
} from "../manualStockTakingSelectors";
import { submitStockTakesRequested } from "../manualStockTakingSlice";

import ManualStockTakingLoadingModal from "./ManualStockTakingLoadingModal";

const ItemName: React.FC<{ item: ValidatedItem }> = function ({ item }) {
  return (
    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
      {item.description1} {item.description2} {item.name}
    </div>
  );
};

interface QuantityProps {
  number: number | undefined;
  unit: string;
  /** Whether to always display the sign. */
  diff?: boolean;
  /** Whether to show the text in a warning color. */
  warn?: boolean;
  "data-cy"?: string;
}

const Quantity: React.FC<QuantityProps> = function ({
  number,
  unit,
  diff,
  warn,
  "data-cy": dataCy,
}) {
  const formattedNumber =
    number === undefined
      ? "???"
      : Intl.NumberFormat(
          i18n.language,
          diff ? { signDisplay: "exceptZero" } : undefined
        ).format(number);

  return (
    <div
      className={classNames("min-w-[3.5rem] text-right tabular-nums", {
        "text-theme-status-attention": warn,
      })}
      data-cy={dataCy}
    >
      {formattedNumber} {unit}
    </div>
  );
};

export const ManualStockTakingReviewModal: React.FC = function () {
  const isLoading = useSelector(selectIsReviewLoading);
  const error = useSelector(selectReviewLoadError);
  const data = useSelector(selectReviewData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector(selectManualStockTakingStatus);
  const { t } = useTranslation("common");

  if (error) {
    return (
      <ErrorModal
        category="stockTakeReview"
        error={error}
        onClose={() => navigate.back()}
        data-cy="ManualStockTakingReviewModal-error"
        data-error-reason={error.reason}
      />
    );
  }

  if (status === "submittingStockTakes") {
    return (
      <ManualStockTakingLoadingModal
        headerText={t("stockTaking.submittingStockTakeHeader")}
        detailText={t("stockTaking.submittingStockTakeDetail")}
        onClose={() => navigate.back()}
      />
    );
  }

  if (data) {
    // TODO Translations
    const negativeDiff =
      data.reduce(
        (sum, x) =>
          x.totalWeightedAverageCostDiff !== undefined &&
          x.totalWeightedAverageCostDiff < 0
            ? sum + x.totalWeightedAverageCostDiff
            : sum,
        0
      ) || undefined;

    const positiveDiff =
      data.reduce(
        (sum, x) =>
          x.totalWeightedAverageCostDiff !== undefined &&
          x.totalWeightedAverageCostDiff > 0
            ? sum + x.totalWeightedAverageCostDiff
            : sum,
        0
      ) || undefined;

    const handleClose = () => {
      navigate.back();
    };

    return (
      <SlideUpModal
        header={t("stockTakingReview.overview")}
        onClose={handleClose}
        data-cy="ManualStockTakingReviewModal"
        fullSize
      >
        <ul className="grow flex flex-col gap-1 px-2 pb-4 text-theme-secondary-gray-700 text-body-xs">
          {data.map((item) => {
            const {
              itemId,
              unit,
              stockTakingQuantity,
              expectedQuantity,
              expectedQuantityWhenCounted,
              canStockTake,
              comment,
              currency,
              quantityDiff,
              totalWeightedAverageCostDiff,
              validationErrorType,
            } = item;

            if (expectedQuantityWhenCounted !== expectedQuantity) {
              return (
                <li key={itemId} data-cy="ManualStockTakingReviewModal-error">
                  TODO How should this be displayed?
                  <br />
                  <b>{itemId}</b> Lagersaldot i Vivaldi har ändrats sedan du
                  inventerade den här artikeln.
                </li>
              );
            }

            if (!canStockTake || validationErrorType) {
              return (
                <li key={itemId} data-cy="ManualStockTakingReviewModal-error">
                  TODO How should this be displayed?
                  <br />
                  <b>{itemId}</b> error - {validationErrorType}.
                </li>
              );
            }

            if (expectedQuantity === stockTakingQuantity) {
              return (
                <li key={itemId} className="flex gap-4 px-2 pb-1">
                  <div className="basis-0 grow min-w-0">
                    <ItemName item={item} />
                    <div>
                      {t("stockTakingReview.itemIdShort")} {itemId}
                    </div>
                  </div>
                  {/* TODO Show comment if one exists? */}
                  <Quantity number={expectedQuantity} unit={unit} />
                </li>
              );
            }

            return (
              <li
                key={itemId}
                className="p-2 bg-theme-secondary-bg-100 flex flex-col gap-1.5"
              >
                <div className="flex gap-4">
                  <div className="basis-0 grow min-w-0">
                    <ItemName item={item} />
                    <div>
                      <b>{t("stockTakingReview.itemIdShort")}</b> {itemId}
                    </div>
                  </div>
                  <Quantity number={stockTakingQuantity} unit={unit} warn />
                </div>
                {comment && (
                  <div
                    data-cy="ManualStockTakingReviewModal-comment"
                    className="whitespace-pre-wrap"
                  >
                    <b>{t("stockTakingReview.commentColon")}</b> {comment}
                  </div>
                )}
                <div className="flex gap-4">
                  <b className="basis-0 grow">{t("stockTakingReview.diff")}</b>
                  <Quantity
                    number={totalWeightedAverageCostDiff}
                    unit={currency}
                    diff
                    warn
                    data-cy="ManualStockTakingReviewModal-totalWeightedAverageCostDiff"
                  />
                  <Quantity
                    number={quantityDiff}
                    unit={unit}
                    diff
                    warn
                    data-cy="ManualStockTakingReviewModal-quantityDiff"
                  />
                </div>
              </li>
            );
          })}
        </ul>
        <div className="bg-theme-primary-ultra-light pt-4 px-4 pb-6">
          <Typography variant="body-sm" className="mb-4">
            <div className="flex">
              <span className="basis-0 grow">
                {t("stockTakingReview.stockTakenItems")}
              </span>
              <Quantity
                number={data.length}
                unit={t("stockTakingReview.numberOfItemsUnit")}
              />
            </div>
            {negativeDiff !== undefined && negativeDiff !== 0 && (
              <div className="flex">
                <span className="basis-0 grow">
                  {t("stockTakingReview.diff")}
                </span>
                <Quantity
                  number={negativeDiff}
                  unit={data[0].currency}
                  diff
                  warn
                />
              </div>
            )}
            {positiveDiff !== undefined && positiveDiff !== 0 && (
              <div className="flex">
                <span className="basis-0 grow">
                  {t("stockTakingReview.diff")}
                </span>
                <Quantity
                  number={positiveDiff}
                  unit={data[0].currency}
                  diff
                  warn
                />
              </div>
            )}
          </Typography>
          <div className="flex gap-4 mb-4">
            <Button
              variant="tertiary"
              className="grow"
              onClick={handleClose}
              data-cy="ManualStockTakingReviewModal-back"
            >
              {t("back")}
            </Button>
            <Button
              variant="primary"
              className="grow"
              onClick={() => {
                dispatch(
                  submitStockTakesRequested({
                    enableAutomaticCompletion: true,
                  })
                );
              }}
              data-cy="ManualStockTakingReviewModal-confirm"
            >
              {t("confirm")}
            </Button>
          </div>
          <Typography variant="body-xs" color="gray">
            {t("stockTakingReview.completionExplanation")}
          </Typography>
        </div>
      </SlideUpModal>
    );
  }

  if (isLoading) {
    return (
      <ManualStockTakingLoadingModal
        headerText={t("stockTakingReview.loadingOverview")}
        onClose={() => navigate.back()}
      />
    );
  }

  // TODO This shouldn't be possible, except for a *very* brief moment. Do we need to do anything special here?
  return null;
};
