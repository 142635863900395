import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectItems } from "../manualStockTakingSelectors";
import { deleteItem } from "../manualStockTakingSlice";

const ManualStockTakingItemRemoveModal: React.ComponentType<ViewComponentProps> =
  function ({ params }) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector(selectItems);

    const itemId = params?.itemId;

    if (typeof itemId !== "string") {
      navigate.back();
      throw new Error("Missing params.itemId");
    }

    useEffect(() => {
      if (!items.some((x) => x.itemId === itemId)) {
        navigate(routes.stockTaking.manual, {}, { replace: true });
      }
    }, [navigate, itemId, items]);

    const handleCancel = () => navigate.back();

    return (
      <Modal open onCancel={handleCancel} variant="card">
        <ModalHeader data-cy="ManualStockTakingItemRemoveModal">
          {t("stockTaking.removeItemRowHeader")}
        </ModalHeader>
        <ModalContent>
          {t("stockTaking.removeItemRowBody", { partNumber: itemId })}
        </ModalContent>
        <ModalActions>
          <ModalAction
            mode="button"
            variant="tertiary"
            onClick={handleCancel}
            data-cy="ManualStockTakingItemRemoveModal-cancel"
          >
            {t("cancel")}
          </ModalAction>
          <ModalAction
            mode="button"
            onClick={() => {
              dispatch(deleteItem(itemId));
              navigate.back(2);
            }}
            data-cy="ManualStockTakingItemRemoveModal-ok"
          >
            {t("yes")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default ManualStockTakingItemRemoveModal;
